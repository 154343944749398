const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/admin/__layout.reset.svelte"),
	() => import("../../../src/routes/auth/__layout.reset.svelte"),
	() => import("../../../src/routes/auth/index.svelte"),
	() => import("../../../src/routes/auth/reset-password.svelte"),
	() => import("../../../src/routes/auth/sign-up.svelte"),
	() => import("../../../src/routes/app/__layout.reset.svelte"),
	() => import("../../../src/routes/app/index.svelte"),
	() => import("../../../src/routes/app/user/index.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/auth/index.svelte
	[/^\/auth\/?$/, [c[4], c[5]], []],

	// src/routes/auth/reset-password.svelte
	[/^\/auth\/reset-password\/?$/, [c[4], c[6]], []],

	// src/routes/auth/sign-up.svelte
	[/^\/auth\/sign-up\/?$/, [c[4], c[7]], []],

	// src/routes/api/authchange.ts
	[/^\/api\/authchange\/?$/],

	// src/routes/api/emaillink.ts
	[/^\/api\/emaillink\/?$/],

	// src/routes/api/logout.ts
	[/^\/api\/logout\/?$/],

	// src/routes/api/signup.ts
	[/^\/api\/signup\/?$/],

	// src/routes/api/login.ts
	[/^\/api\/login\/?$/],

	// src/routes/app/index.svelte
	[/^\/app\/?$/, [c[8], c[9]], []],

	// src/routes/app/user/index.svelte
	[/^\/app\/user\/?$/, [c[8], c[10]], []]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];